// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.


.cell
  padding: $cs.m

  +media-query($bp.s)
    padding: $cs.m $cs.xs

  &:first-child, &:last-child
    padding-right: $cs.s

  &:first-child
    font-weight: $fw.bold

  &:last-child:not(&-centered)
    text-align: right

  &-small
    padding-top: $cs.xs
    padding-bottom: $cs.xs

  &-centered
    text-align: center

  &-head
    font-weight: normal
    font-size: $fs.s
    color: $tc-subtle-gray

  &-data
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis


  &-head:not(&-centered)
    text-align: left
